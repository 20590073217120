@import "./normalize.css";

:root {
  --color-primary: #6b0e2a;
  --color-secondary: #ffbb45;
  --color-accent: #ff3400;
  --color-headings: #1b0760;
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
  --color-border: #ccc;
  --border-radius: 30px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Typography */

html {
  /* 62.5% of default 16px is 10px*/
  font-size: 62.5%;
}

@media screen and (max-width: 768px) {
  .htmlFixed,
  .bodyFixed {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  color: var(--color-body);
}

.logo {
  width: 51.4px;
  height: 51.4px;
  border: 1px solid #6b0e2a;
  margin: 5px;
  margin-right: 3px;
  border-radius: 5px;
  position: relative;
  top: 2px;
}

.cls-3 {
  font-size: 32.498px;
  color: #6b0e2a;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  bottom: 50px;
  position: relative;
  top: 7.2px;
  z-index: 1;
  border: 1px solid #6b0e2a;
  border-radius: 0px 5px 5px 0px;
  padding-left: 2px;
}

.loading {
  /* background: #6b0e2a; */
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 4px;
  position: relative;
  z-index: 0;
  /* Here we declare our inline animation */
  animation: rotate 5s linear infinite, moving 3s linear infinite;
  animation-delay: 2s;
}

.loading__reverse {
  /* background: white; */
  animation: rotate__opposite 5s linear infinite,
    moving__opposite 3s linear infinite;
  animation-delay: 2s;
}

/* Here we define our animation with the name "rotate" */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
@keyframes moving {
  0%,
  100% {
    right: 20px;
    top: -170px;
  }
  25%,
  75% {
    right: -100px;
    top: -50px;
  }

  50% {
    right: -70vh;
    top: -70vh;
  }
}

@keyframes rotate__opposite {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

@keyframes moving__opposite {
  0%,
  100% {
    right: 80vh;
    top: 50vh;
  }
  25%,
  75% {
    right: -10px;
    top: -50px;
  }
  50% {
    right: 80px;
    top: 0;
  }
}

.startingPos {
  right: 20px;
  top: -170px;
}

@media screen and (min-width: 768px) {
  .loading {
    animation: rotate 3s linear infinite;
    animation-delay: 2s;
  }
  .loading__reverse {
    animation: rotate__opposite 3s linear infinite;
    animation-delay: 2s;
  }
  .startingPos {
    right: 0px;
    top: -100px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
    0%,
    100% {
      right: 0;
      top: -100px;
    }
    25%,
    75% {
      right: -100px;
      top: -50px;
    }

    50% {
      right: -5000px;
      top: -2500px;
    }
  }

  @keyframes rotate__opposite {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-359deg);
    }
    0%,
    100% {
      right: 5000px;
      top: -3000px;
    }
    25%,
    75% {
      right: 50px;
      top: 100px;
    }
    50% {
      right: 100px;
      top: 0;
    }
  }
}

h1,
h2,
h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
  letter-spacing: 0px;
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
  font-weight: 500;
}

p {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }
  h1 {
    font-size: 8rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2.4rem;
  }
}

/* Links */

a {
  text-decoration: none;
}

/* Lists */

.list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0rem;
  margin-top: 1rem;
}

/* Icons */
.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-self: center;
  color: #a54a64;
}

.icon--white {
  fill: #a54a64;
}

/* Buttons */

.btn {
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 800;
  margin: 1rem 0;
  padding: 2rem 4vw;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  outline: 0;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--accent {
  background: #a54a64;
  /* 3b99a8 */
  color: white;
}

.btn--accent:hover {
  background: #22daf5;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media screen and (min-width: 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}

/* Grids */

.grid {
  display: grid;
}

@media screen and (min-width: 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Collapsibles */

.collapsible__header {
  display: flex;
  justify-content: space-between;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
  color: white;
}

.collapsible__chevron {
  transform: rotate(0deg);
  background: darkslategrey;
  border-radius: 100%;
  transition: 0.3s;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
  opacity: 0;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(90deg);
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

/* Navigation Bar */

.nav {
  background: #ffbb45;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem;
  align-items: center;
}

.nav__list {
  width: 100%;
  margin: 0;
  font-size: 2rem;
}

.nav__item {
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #ff0059;
}

.nav__item > a {
  color: #a54a64;
  transition: color 0.1s;
}

.nav__item > a:hover {
  color: #fff;
}

.nav__toggler {
  opacity: 0.5;
  transition: box-shadow 0.1s;
  cursor: pointer;
}

.nav.collapsible--expanded .nav__toggler {
  opacity: 1;
  box-shadow: 0 0 0 3px #ff0059;
  border-radius: 5px;
}

.nav__brand {
  transform: translateY(5px);
}

.socials {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: -2px;
}

@media screen and (min-width: 768px) {
  .nav {
    border-bottom: solid 2.5px #121212;
    border-left-style: solid;
    border-left-color: #121212;
    border-left-width: 2.5px;
    border-right-style: solid;
    border-right-color: #121212;
    border-right-width: 2.5px;
    border-top-style: solid;
    border-top-color: #121212;
    border-top-width: 2.5px;
  }

  .nav__toggler {
    display: none;
  }

  .nav__list {
    width: auto;
    display: flex;
    font-size: 2rem;
    max-height: 100%;
    opacity: 1;
    border-left-style: solid;
    border-left-color: #121212;
    border-left-width: 2.5px;
    border-right-style: solid;
    border-right-color: #121212;
    border-right-width: 2.5px;
    padding: 22px 0px;
  }

  .nav__item {
    border: 0;
  }
}

/* Blocks */

.block {
  --padding-vertical: 5rem;
  padding: var(--padding-vertical) 2rem;
  position: relative;
  top: -0.3px;
}

.block__header {
  text-align: center;
  margin-bottom: 4rem;
  z-index: 1;
}

.block__heading {
  margin-top: 0;
}

.block--dark {
  background: #ffbb45;
  color: #7b858b;
}

.block--dark .block__heading {
  color: #fff;
}

.block--dark h1,
.block--dark h2,
.block--dark h3 {
  color: #fff;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  height: 80vh;
}
@media screen and (min-width: 768px) {
  .container {
    height: 100vh;
  }
}
/* Hero */

.hero__tagline {
  font-size: 2rem;
  color: #532b2b;
  letter-spacing: 1px;
  margin: 2rem 0 5rem;
}

.hero__image {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .hero {
    padding-top: 10px;
  }

  .hero__content {
    text-align: left;
    align-self: center;
  }

  .hero__border {
    border-bottom-style: solid;
    border-bottom-color: #121212;
    border-bottom-width: 2.5px;
    border-left-style: solid;
    border-left-color: #121212;
    border-left-width: 2.5px;
    border-right-style: solid;
    border-right-color: #121212;
    border-right-width: 2.5px;
  }
}

/* Footer */

.footer {
  background: #121212;
  padding-top: 1rem;
  border-bottom-style: solid;
  border-bottom-color: #121212;
  border-bottom-width: 2.5px;
}

.footer a {
  color: #777;
  transition: color 0.3s;
}

.footer a:hover {
  color: #fff;
}

.footer__section {
  padding: 1rem;
  border-bottom: 1px solid #393939;
}

.footer__secton .list {
  margin: 0;
}

.footer__heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.5;
}

.footer__brand {
  margin-top: 1rem;
  text-align: center;
}

.footer__brand img {
  /* TODO: Consider refracting this and applying to all images. */
  width: 100%;
  max-width: 230px;
}

.footer__copyright {
  font-size: 2.1rem;
  color: #fff;
  opacity: 0.3;
}

@media screen and (min-width: 1024px) {
  .footer {
    font-size: 1.5rem;
  }

  .footer__sections {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }

  .footer .collapsible__chevron {
    display: none;
  }

  .footer .collapsible__content {
    opacity: 1;
    max-height: 100%;
  }

  .footer__brand {
    order: -1;
    margin-top: 1rem;
  }

  .footer__copyright {
    font-size: 1.5rem;
  }

  .footer__section {
    border: 0;
  }

  .footer__heading {
    font-size: 1.6rem;
  }
}
